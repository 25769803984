import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import MicIcon from '@mui/icons-material/Mic';
import FormatColorTextIcon from '@mui/icons-material/FormatColorText';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import TextField from '@mui/material/TextField';
import { useStateValue } from '../store';
import { LANGUAGE_MAP } from '../utils/languageMap';

function SimpleDialog(props) {
    const { onClose, onChange, languages, open, filterLanguages, setFilterLanguages } = props;

    const handleClose = () => {
        onClose();
    };

    const handleListItemClick = (value) => {
        onChange(value);
    };

    const languagesToRender = languages.filter((language) => {
        if (!!filterLanguages && filterLanguages.trim() !== '') {
            const escapedFilter = filterLanguages.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
            const regexLanguage = new RegExp(escapedFilter, 'i');
            return language.label.match(regexLanguage);
        } else {
            return true;
        }
    });

    const renderServicesLanguage = (lng) => {
        const isTTT = lng.services.includes('ttt');
        const isSTT = lng.services.includes('stt');
        const isFull = lng.services.includes('stt-ttt-tts');

        return (
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {isSTT && (
                    <div style={{ marginRight: 2 }}>
                        <MicIcon />
                    </div>
                )}
                {isTTT && (
                    <div style={{ marginRight: 2 }}>
                        <FormatColorTextIcon />
                    </div>
                )}
                {isFull && (
                    <div style={{ marginRight: 2 }}>
                        <VolumeUpIcon />
                    </div>
                )}
            </div>
        );
    };

    return (
        <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth={2000}>
            <Box
                sx={{
                    width: 'calc(100% - 80px)',
                    position: 'fixed',
                    backgroundColor: 'white',
                    zIndex: 20,
                }}
            >
                <Box
                    sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                >
                    <DialogTitle sx={{ color: 'black' }}>
                        <FormattedMessage
                            id="selectLanguage"
                            defaultMessage="Please select your language"
                        />
                    </DialogTitle>
                    <IconButton aria-label="delete" onClick={onClose}>
                        <CancelIcon />
                    </IconButton>
                </Box>
                <Box sx={{ width: 'calc(100% - 60px)', margin: '0 auto' }}>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        name="email"
                        label="Search language..."
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={(e) => setFilterLanguages(e.target.value)}
                    />
                </Box>
            </Box>

            <List sx={{ pt: 0, top: 150, minHeight: 'calc(100vh - 50px)' }}>
                {languagesToRender.map((language) => (
                    <ListItem
                        button
                        onClick={() => handleListItemClick(language.value)}
                        key={language.id}
                    >
                        <ListItemAvatar>
                            <Avatar src={language.img} />
                        </ListItemAvatar>
                        <ListItemText primary={language.label} />
                        <ListItemSecondaryAction>
                            {renderServicesLanguage(language)}
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
            </List>
        </Dialog>
    );
}

SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string.isRequired,
};

export default function LanguageSelectionDialog({ open, handleOpen }) {
    const [{ languages }, dispatch] = useStateValue();
    const [filterLanguages, setFilterLanguages] = React.useState(null);

    const handleChange = (value) => {
        handleOpen(false);
        setFilterLanguages(null);

        const matchedLanguage = Object.keys(LANGUAGE_MAP).find((key) =>
            value.match(new RegExp(key, 'i'))
        );

        if (matchedLanguage) {
            return dispatch({
                type: 'SET_LANGUAGE',
                payload: { lng: value, intl: LANGUAGE_MAP[matchedLanguage] },
            });
        }

        dispatch({
            type: 'SET_LANGUAGE',
            payload: { lng: value, intl: value },
        });
    };

    const handleClose = (value) => {
        setFilterLanguages(null);
        handleOpen(false);
    };

    return (
        <SimpleDialog
            selectedValue={languages.defaultLanguage}
            open={open}
            onClose={handleClose}
            onChange={handleChange}
            languages={languages.data}
            filterLanguages={filterLanguages}
            setFilterLanguages={setFilterLanguages}
        />
    );
}
