import { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer/legacy';
import { Link as RouterLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Home from '@mui/icons-material/Home';

import './CustomerSurvey.css';

function CustomerSurvey(props) {
    let vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    let vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
    const [remaining, setRemaining] = useState(0);

    const { getRemainingTime } = useIdleTimer({
        onIdle: () => window.location.replace('/'),
        timeout: 1000 * 60 * 5,
    });

    useEffect(() => {
        const interval = setInterval(() => {
            setRemaining(Math.ceil(getRemainingTime() / 1000));
        }, 500);

        return () => {
            clearInterval(interval);
        };
    });

    return (
        <div className="App-customer-survey">
            <iframe src="https://sprw.io/stt-nc3wX" width={vw} height={vh} frameBorder="0"></iframe>
            <div className="Wrapper-back-button">
                <Box mt={4}>
                    <Button
                        variant="contained"
                        color="secondary"
                        sx={{ width: '100%' }}
                        component={RouterLink}
                        to="/"
                    >
                        <Home />
                        &nbsp; &nbsp;
                        <FormattedMessage id="backMainMenu" defaultMessage="Back to Main Menu" />
                    </Button>
                </Box>
            </div>
        </div>
    );
}

export default CustomerSurvey;
