export const LANGUAGE_MAP = {
    spanish: 'spanish',
    english: 'english',
    arabic: 'arabic',
    urdu: 'ur',
    tamil: 'tamil',
    bengali: 'bengali',
    azerbaijani: 'az',
    zulu: 'zl',
    vietnamese: 'vi-VN',
    portuguese: 'pt',
    afrikaans: 'af',
    belarusian: 'be',
    bulgarian: 'bg',
    chinese: 'zh',
    chamorro: 'chamorro',
    dutch: 'nl',
    esperanto: 'eo',
    french: 'fr',
    german: 'de',
    hungarian: 'hu',
    icelandic: 'is',
    igbo: 'ig',
    indonesian: 'id',
    italian: 'it',
    kinyarwanda: 'rw',
    lithuanian: 'lt',
    luxembourgish: 'lu',
    macedonian: 'mk',
    malayalam: 'ml',
    mongolian: 'mn',
    norwegian: 'no',
    odia: 'or',
    slovenian: 'sl',
    sundanese: 'su',
    thai: 'th',
    ukrainian: 'uk',
    kiswahili: 'sw',
    hill: 'mrj',
    papiamento: 'pap',
    yucatec: 'yua',
    assamese: 'asm',
    avar: 'ava',
    alur: 'alz',
    inuinnaqtun: 'ikt',
    chhattisgarhi: 'hne',
    kapampangan: 'pam',
    mizo: 'lus',
    sus: 'sus',
    ndau: 'ndc',
    inuktitut: 'iku',
    shan: 'shn',
    komi: 'kv',
    limburgan: 'lim',
    manx: 'gv',
    cantonese: 'yue',
    kalaallisut: 'kl',
    dari: 'fa',
    marshallese: 'mh',
    eqchi: 'kek',
    nuer: 'nus',
    tamazight: 'zgh',
    hiligaynon: 'hil',
    bodo: 'boy',
    pisin: 'tpi',
    'sami-north': 'north',
    krio: 'kri',
    ndebele: 'nbl',
    pangasinan: 'pag',
    mari: 'mhr',
    tulu: 'tcy',
    kiga: 'cgg',
    sorani: 'ckb',
    iban: 'iba',
    batak: 'bts',
    nahuatl: 'nah',
    jamaican: 'jam',
    sorbian: 'sorbian',
    hakha: 'cnh',
    newari: 'new',
    albanian: 'albanian-albania',
    farsi: 'farsi',
};
