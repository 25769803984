import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    typography: {
        fontFamily: [
          '-apple-system',
          'BlinkMacSystemFont',
          '"Segoe UI"',
          'Roboto',
          '"Helvetica Neue"',
          'Arial',
          'sans-serif',
          'Noto Sans Meetei Mayek',
        ].join(','),
    },
    palette: {
        primary: {
            main: '#2FC1E6',
        },
        secondary: {
            main: '#372B65',
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: ({ ownerState, theme }) => ({
                    textTransform: 'none',
                    ...(ownerState.variant === 'contained' &&
                        ownerState.color === 'primary' && {
                            color: '#fff',
                            fontWeight: 400,
                        }),
                    ...(ownerState.variant === 'outlined' && {
                        color: '#505050',
                        borderColor: theme.palette.secondary.main,
                        fontWeight: 400,
                    }),
                }),
            },
        },
    },
});

export default theme;
