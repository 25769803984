import { useState, useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer/legacy';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Link as RouterLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import KioskButton from '../theme/Button';
import LanguageSelectionDialog from '../components/LanguageSelection';
import Footer from '../components/Footer';
import { useStateValue } from '../store';
import logo from '../logo.svg';
import './AirlinesMulticalling.css';

import aerLingus from '../assets/airlines/aer-lingus.png';
import aeroMexico from '../assets/airlines/aeromexico.png';
import airCanada from '../assets/airlines/air-canada.png';
import airFrance from '../assets/airlines/air-france.png';
import alaska from '../assets/airlines/alaska.png';
import allegiant from '../assets/airlines/allegiant.png';
import american from '../assets/airlines/american.png';
import british from '../assets/airlines/british.png';
import cayman from '../assets/airlines/cayman.png';
import copa from '../assets/airlines/copa.png';
import delta from '../assets/airlines/delta.png';
import denverAir from '../assets/airlines/denver-air.png';
import edelweiss from '../assets/airlines/edelweiss.png';
import frontier from '../assets/airlines/frontier.png';
import icelandair from '../assets/airlines/icelandair.png';
import jetblue from '../assets/airlines/jetblue.png';
import lufthansa from '../assets/airlines/lufthansa.png';
import southern from '../assets/airlines/southern.png';
import southwest from '../assets/airlines/southwest.png';
import suncountry from '../assets/airlines/suncountry.png';
import united from '../assets/airlines/united.png';
import vivaaerobus from '../assets/airlines/vivaaerobus.png';
import volaris from '../assets/airlines/volaris.png';
import westjet from '../assets/airlines/westjet.png';

function AirlinesMulticalling() {
    const [openDialog, setOpenDialog] = useState(false);
    const [{ languages }] = useStateValue();
    const [remaining, setRemaining] = useState(0);

    const { getRemainingTime } = useIdleTimer({
        onIdle: () => window.location.replace('/'),
        timeout: 60000,
    });

    useEffect(() => {
        const interval = setInterval(() => {
            setRemaining(Math.ceil(getRemainingTime() / 1000));
        }, 500);

        return () => {
            clearInterval(interval);
        };
    });

    return (
        <div className="App">
            <header className="App-header">
                <div className="Header-logo">
                    <Button href="/">
                        <img src={logo} className="App-logo" alt="logo" />
                    </Button>
                </div>
            </header>
            <main className="App-main">
                <h1>
                    <FormattedMessage
                        id="selectAirline"
                        defaultMessage="Select your airline agent"
                    />
                </h1>
                <div className="Button-wrapper">
                    <KioskButton
                        variant="outlined"
                        iconbgcolor="#ffffff"
                        size="large"
                        startIcon={<img src={aerLingus} width={50} height={50} />}
                        href={`https://airportcall.worldwidetechconnections.com/?local=${languages.defaultLanguage}&remote=english-united-states&number=18004747424&autoStart=true&showKeypad=true&showInstructions=true&direct=true`}
                    >
                        Aer Lingus
                    </KioskButton>
                </div>
                <div className="Button-wrapper">
                    <KioskButton
                        variant="outlined"
                        iconbgcolor="#001D42"
                        size="large"
                        startIcon={<img src={aeroMexico} width={50} height={50} />}
                        href={`https://airportcall.worldwidetechconnections.com/?local=${languages.defaultLanguage}&remote=english-united-states&number=18002376639&autoStart=true&showKeypad=true&showInstructions=true&direct=true`}
                    >
                        AeroMexico
                    </KioskButton>
                </div>
                <div className="Button-wrapper">
                    <KioskButton
                        variant="outlined"
                        iconbgcolor="#D9252E"
                        size="large"
                        startIcon={<img src={airCanada} width={50} height={50} />}
                        href={`https://airportcall.worldwidetechconnections.com/?local=${languages.defaultLanguage}&remote=english-united-states&number=18882472262&autoStart=true&showKeypad=true&showInstructions=true&direct=true`}
                    >
                        Air Canada
                    </KioskButton>
                </div>
                <div className="Button-wrapper">
                    <KioskButton
                        variant="outlined"
                        iconbgcolor="#ffffff"
                        size="large"
                        startIcon={<img src={airFrance} width={50} height={50} />}
                        href={`https://airportcall.worldwidetechconnections.com/?local=${languages.defaultLanguage}&remote=english-united-states&number=18002372747&autoStart=true&showKeypad=true&showInstructions=true&direct=true`}
                    >
                        Air France
                    </KioskButton>
                </div>
                <div className="Button-wrapper">
                    <KioskButton
                        variant="outlined"
                        iconbgcolor="#ffffff"
                        size="large"
                        startIcon={<img src={alaska} width={50} height={50} />}
                        href={`https://airportcall.worldwidetechconnections.com/?local=${languages.defaultLanguage}&remote=english-united-states&number=18002527522&autoStart=true&showKeypad=true&showInstructions=true&direct=true`}
                    >
                        Alaska
                    </KioskButton>
                </div>
                <div className="Button-wrapper">
                    <KioskButton
                        variant="outlined"
                        iconbgcolor="#ffffff"
                        size="large"
                        startIcon={<img src={allegiant} width={50} height={50} />}
                        href={`https://airportcall.worldwidetechconnections.com/?local=${languages.defaultLanguage}&remote=english-united-states&number=17025058888&autoStart=true&showKeypad=true&showInstructions=true&direct=true`}
                    >
                        Allegiant
                    </KioskButton>
                </div>
                <div className="Button-wrapper">
                    <KioskButton
                        variant="outlined"
                        iconbgcolor="#ffffff"
                        size="large"
                        startIcon={<img src={american} width={50} height={50} />}
                        href={`https://airportcall.worldwidetechconnections.com/?local=${languages.defaultLanguage}&remote=english-united-states&number=18004337300&autoStart=true&showKeypad=true&showInstructions=true&direct=true`}
                    >
                        American
                    </KioskButton>
                </div>
                <div className="Button-wrapper">
                    <KioskButton
                        variant="outlined"
                        iconbgcolor="#ffffff"
                        size="large"
                        startIcon={<img src={british} width={50} height={50} />}
                        href={`https://airportcall.worldwidetechconnections.com/?local=${languages.defaultLanguage}&remote=english-united-states&number=18002479297&autoStart=true&showKeypad=true&showInstructions=true&direct=true`}
                    >
                        British Airways
                    </KioskButton>
                </div>
                <div className="Button-wrapper">
                    <KioskButton
                        variant="outlined"
                        iconbgcolor="#ffffff"
                        size="large"
                        startIcon={<img src={cayman} width={50} height={50} />}
                        href={`https://airportcall.worldwidetechconnections.com/?local=${languages.defaultLanguage}&remote=english-united-states&number=18004229626&autoStart=true&showKeypad=true&showInstructions=true&direct=true`}
                    >
                        Cayman
                    </KioskButton>
                </div>
                <div className="Button-wrapper">
                    <KioskButton
                        variant="outlined"
                        iconbgcolor="#02329C"
                        size="large"
                        startIcon={<img src={copa} width={50} height={50} />}
                        href={`https://airportcall.worldwidetechconnections.com/?local=${languages.defaultLanguage}&remote=english-united-states&number=17868402672&autoStart=true&showKeypad=true&showInstructions=true&direct=true`}
                    >
                        Copa
                    </KioskButton>
                </div>
                <div className="Button-wrapper">
                    <KioskButton
                        variant="outlined"
                        iconbgcolor="#ffffff"
                        size="large"
                        startIcon={<img src={delta} width={50} height={50} />}
                        href={`https://airportcall.worldwidetechconnections.com/?local=${languages.defaultLanguage}&remote=english-united-states&number=18002211212&autoStart=true&showKeypad=true&showInstructions=true&direct=true`}
                    >
                        Delta
                    </KioskButton>
                </div>
                <div className="Button-wrapper">
                    <KioskButton
                        variant="outlined"
                        iconbgcolor="#ffffff"
                        size="large"
                        startIcon={<img src={denverAir} width={50} height={50} />}
                        href={`https://airportcall.worldwidetechconnections.com/?local=${languages.defaultLanguage}&remote=english-united-states&number=18663738513&autoStart=true&showKeypad=true&showInstructions=true&direct=true`}
                    >
                        Denver Air
                    </KioskButton>
                </div>
                <div className="Button-wrapper">
                    <KioskButton
                        variant="outlined"
                        iconbgcolor="#ffffff"
                        size="large"
                        startIcon={<img src={edelweiss} width={50} height={50} />}
                        href={`https://airportcall.worldwidetechconnections.com/?local=${languages.defaultLanguage}&remote=english-united-states&number=16692721225&autoStart=true&showKeypad=true&showInstructions=true&direct=true`}
                    >
                        Edelweiss
                    </KioskButton>
                </div>
                <div className="Button-wrapper">
                    <KioskButton
                        variant="outlined"
                        iconbgcolor="#ffffff"
                        size="large"
                        startIcon={<img src={frontier} width={50} height={50} />}
                        href={`https://airportcall.worldwidetechconnections.com/?local=${languages.defaultLanguage}&remote=english-united-states&number=18014019000&autoStart=true&showKeypad=true&showInstructions=true&direct=true`}
                    >
                        Frontier
                    </KioskButton>
                </div>
                <div className="Button-wrapper">
                    <KioskButton
                        variant="outlined"
                        iconbgcolor="#ffffff"
                        size="large"
                        startIcon={<img src={icelandair} width={50} height={50} />}
                        href={`https://airportcall.worldwidetechconnections.com/?local=${languages.defaultLanguage}&remote=english-united-states&number=18002235500&autoStart=true&showKeypad=true&showInstructions=true&direct=true`}
                    >
                        Icelandair
                    </KioskButton>
                </div>
                <div className="Button-wrapper">
                    <KioskButton
                        variant="outlined"
                        iconbgcolor="#ffffff"
                        size="large"
                        startIcon={<img src={jetblue} width={50} height={50} />}
                        href={`https://airportcall.worldwidetechconnections.com/?local=${languages.defaultLanguage}&remote=english-united-states&number=18005382583&autoStart=true&showKeypad=true&showInstructions=true&direct=true`}
                    >
                        JetBlue
                    </KioskButton>
                </div>
                <div className="Button-wrapper">
                    <KioskButton
                        variant="outlined"
                        iconbgcolor="#ffffff"
                        size="large"
                        startIcon={<img src={lufthansa} width={50} height={50} />}
                        href={`https://airportcall.worldwidetechconnections.com/?local=${languages.defaultLanguage}&remote=english-united-states&number=18006453880&autoStart=true&showKeypad=true&showInstructions=true&direct=true`}
                    >
                        Lufthansa
                    </KioskButton>
                </div>
                <div className="Button-wrapper">
                    <KioskButton
                        variant="outlined"
                        iconbgcolor="#ffffff"
                        size="large"
                        startIcon={<img src={southern} width={50} height={50} />}
                        href={`https://airportcall.worldwidetechconnections.com/?local=${languages.defaultLanguage}&remote=english-united-states&number=18003290485&autoStart=true&showKeypad=true&showInstructions=true&direct=true`}
                    >
                        Southern
                    </KioskButton>
                </div>
                <div className="Button-wrapper">
                    <KioskButton
                        variant="outlined"
                        iconbgcolor="#ffffff"
                        size="large"
                        startIcon={<img src={southwest} width={50} height={50} />}
                        href={`https://airportcall.worldwidetechconnections.com/?local=${languages.defaultLanguage}&remote=english-united-states&number=18004359792&autoStart=true&showKeypad=true&showInstructions=true&direct=true`}
                    >
                        Southwest
                    </KioskButton>
                </div>
                <div className="Button-wrapper">
                    <KioskButton
                        variant="outlined"
                        iconbgcolor="#ffffff"
                        size="large"
                        startIcon={<img src={suncountry} width={50} height={50} />}
                        href={`https://airportcall.worldwidetechconnections.com/?local=${languages.defaultLanguage}&remote=english-united-states&number=16519052737&autoStart=true&showKeypad=true&showInstructions=true&direct=true`}
                    >
                        Sun Country
                    </KioskButton>
                </div>
                <div className="Button-wrapper">
                    <KioskButton
                        variant="outlined"
                        iconbgcolor="#ffffff"
                        size="large"
                        startIcon={<img src={united} width={50} height={50} />}
                        href={`https://airportcall.worldwidetechconnections.com/?local=${languages.defaultLanguage}&remote=english-united-states&number=18008648331&autoStart=true&showKeypad=true&showInstructions=true&direct=true`}
                    >
                        United Airlines
                    </KioskButton>
                </div>
                <div className="Button-wrapper">
                    <KioskButton
                        variant="outlined"
                        iconbgcolor="#EAEAEA"
                        size="large"
                        startIcon={<img src={vivaaerobus} width={50} height={50} />}
                        href={`https://airportcall.worldwidetechconnections.com/?local=${languages.defaultLanguage}&remote=english-united-states&number=18663598482&autoStart=true&showKeypad=true&showInstructions=true&direct=true`}
                    >
                        Viva Aerobus
                    </KioskButton>
                </div>
                <div className="Button-wrapper">
                    <KioskButton
                        variant="outlined"
                        iconbgcolor="#ffffff"
                        size="large"
                        startIcon={<img src={volaris} width={50} height={50} />}
                        href={`https://airportcall.worldwidetechconnections.com/?local=${languages.defaultLanguage}&remote=english-united-states&number=18558652747&autoStart=true&showKeypad=true&showInstructions=true&direct=true`}
                    >
                        Volaris
                    </KioskButton>
                </div>
                <div className="Button-wrapper">
                    <KioskButton
                        variant="outlined"
                        iconbgcolor="#ffffff"
                        size="large"
                        startIcon={<img src={westjet} width={50} height={50} />}
                        href={`https://airportcall.worldwidetechconnections.com/?local=${languages.defaultLanguage}&remote=english-united-states&number=18889378538&autoStart=true&showKeypad=true&showInstructions=true&direct=true`}
                    >
                        Westjet
                    </KioskButton>
                </div>
                <Paper
                    elevation={3}
                    sx={{ padding: '1em 3em' }}
                    style={{ maxWidth: 400, margin: '3em auto' }}
                >
                    <Box>
                        <Button
                            variant="outlined"
                            sx={{ width: '100%' }}
                            component={RouterLink}
                            to="/"
                        >
                            <FormattedMessage
                                id="backMainMenu"
                                defaultMessage="Back to Main Menu"
                            />
                        </Button>
                    </Box>
                </Paper>
            </main>
            <Footer />
            <LanguageSelectionDialog open={openDialog} handleOpen={setOpenDialog} />
        </div>
    );
}

export default AirlinesMulticalling;
